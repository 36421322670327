<template>
  <div class="zcxy">
    <a-breadcrumb class="breadcrumb">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item>教务中心</a-breadcrumb-item>
        <a-breadcrumb-item>我的班级</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
        <div class="clearfix table-tools">
            <div class="buttons">
                <a-form layout='inline'>
                <a-form-item>
                    <a-button type="primary" icon="plus" @click="showEditModal()">新开班级</a-button>
                </a-form-item>
                <!-- <a-form-item>
                    <a-button @click="toExport" icon="export" :loading="exportLoading">导出</a-button>
                </a-form-item> -->
                </a-form>
            </div>
            <div class="search">
                <a-form layout='inline' v-if="selectedRowKeys.length == 0" @submit="searchList">
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <a-select :value='searchParams.search.is_sub' @change='handleIsSub' style="width: 100px">
                        <a-select-option :value="0">我的</a-select-option>
                        <a-select-option :value="1">我下属的</a-select-option>
                    </a-select>
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                    <a-tooltip placement="topLeft" >
                    <template slot="title">
                        <span>班级名称</span>
                    </template>
                    <a-input allowClear v-model.trim="searchParams.search.class_name" placeholder="班级名称" style="width: 160px"/>
                    </a-tooltip>
                </a-form-item>
                <a-form-item>
                <a-select style="width: 200px" v-model="searchParams.search.studio_id" mode="multiple" :maxTagCount="1" :maxTagTextLength='5' allowClear placeholder="上课校区" showSearch :filterOption="filterOption">
                    <a-select-option v-for="(item, index) of studios" :key="index" :value="item.studio_id">{{ item.filter_name }}</a-select-option>
                </a-select>
                </a-form-item>
                 <a-form-item>
                  <a-radio-group :value="searchParams.search.status">
                    <a-radio-button @click="handleTypeButChange('1')" value="1">
                        开班中
                    </a-radio-button>
                    <a-radio-button @click="handleTypeButChange('2')" value="2">
                        已结业
                    </a-radio-button>
                    <a-radio-button @click="handleTypeButChange('3')" value="3">
                        待开班
                    </a-radio-button>
                  </a-radio-group>
                </a-form-item>
                <a-form-item>
                    <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
                </a-form-item>
                <a-form-item>
                    <a-popover placement="bottom" trigger="click" v-model="searchVisible">
                    <template slot="content">
                        <div class="more-search">
                        <a-form layout='inline'>
                            <a-form-item class="block-line" label="课程科目" >
                            <a-select v-model="searchParams.search.course_cate_id" showSearch allowClear placeholder="请选择" :filterOption="filterOption">
                                <a-select-option v-for="(item, index) of cCategorys" :key="index" :value="item.course_cate_id">{{ item.filter_name }}</a-select-option>
                            </a-select>
                            </a-form-item>
                            <a-form-item class="block-line" label="授课老师" >
                            <a-select v-model="searchParams.search.tutor_id" showSearch allowClear mode="multiple" placeholder="请选择" :filterOption="filterOption">
                                <a-select-option v-for="(item, index) of teachers" :key="index" :value="item.teacher_id">{{ item.filter_name }}</a-select-option>
                            </a-select>
                            </a-form-item>
                            <a-form-item class="block-line" label="班主任" >
                            <a-select v-model="searchParams.search.cc_id" showSearch allowClear placeholder="请选择" mode="multiple"  :filterOption="filterOption">
                                <a-select-option v-for="(item, index) of consultants" :key="index" :value="item.course_consultant_id">{{ item.filter_name }}</a-select-option>
                            </a-select>
                            </a-form-item>

                            <!-- <a-form-item class="block-line" label="周几授课" >
                            <a-select v-model="searchParams.search.weekly" allowClear placeholder="请选择"  mode="multiple">
                                <a-select-option v-for="(item, index) of classWeeklys" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                            </a-select>
                            </a-form-item> -->
                            <a-form-item class="block-line"  label="班级类型" >
                            <a-select v-model="searchParams.search.class_type" allowClear placeholder="请选择">
                                <a-select-option v-for="(item, index) of classTypes" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                            </a-select>
                            </a-form-item>
                            <!-- <a-form-item class="block-line"  label="班级状态" >
                            <a-select v-model="searchParams.search.status" allowClear placeholder="请选择">
                                <a-select-option v-for="(item, index) of classStatus" :key="index" :value="item.value">{{ item.label }}</a-select-option>
                            </a-select>
                            </a-form-item> -->
                        </a-form>
                        <div class="more-search__buttons">
                            <a-button type="dashed" @click="closeSearch">取消</a-button>
                            <a-button @click="reset">重置</a-button>
                            <a-button type="primary" @click="searchList">搜索</a-button>
                        </div>
                        </div>
                    </template>
                    <a-button>更多<a-icon type="down" /></a-button>
                    </a-popover>
                </a-form-item>
                </a-form>
                <a-form layout='inline' v-else>
                        <a-form-item>
                            已选择<span>{{selectedRowKeys.length}}</span>项
                        </a-form-item>
                        <a-form-item>
                            <a-divider type="vertical" />
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="toClose" icon="apartment">批量结班</a-button>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="toExport" icon="export" :loading="exportLoading">导出学员信息</a-button>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="toMixed" icon="apartment">班级合并</a-button>
                        </a-form-item>
                    </a-form>
            </div>
        </div>
        <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="middle" :pagination="false" :bordered='false' rowKey="class_id"
                :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
                :columns="columns" :dataSource="list" :scroll="{ x: 1500 }" @change="handleChange">
                <template slot="index" slot-scope="text, record , index">
                <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
                <template slot="className" slot-scope="class_name, record">
                <a class="className-ellipsis" @click="showDetailModal(record,'1')">{{ class_name }}</a>
                </template>

                <template slot="classPercent" slot-scope="class_percent, record">
                <a-progress 
                :stroke-color="{
                    '0%': '#00cca2',
                    '100%': '#00cca2',
                }"
                :percent="Number(class_percent)" size="small" :showInfo="true" />
                </template>

                <template slot="classRule" slot-scope="class_rule">
                <div v-for="(item, index) of class_rule" :key="index">{{ item }}</div>
                </template>

                <template slot="class_count" slot-scope="class_count,record">
                  <a @click="showDetailModal(record,'1')">{{class_count}}</a>
                </template>

                <template slot="class_had_course_count" slot-scope="class_had_course_count,record">
                <a @click="showDetailModal(record,'2')">{{class_had_course_count}} / {{record.class_total_course_count}}</a>
                </template>

                <template slot="action" slot-scope="text,record">
                
                <a-dropdown>
                    <a class="ant-dropdown-link" @click="e => e.preventDefault()">操作<a-icon type="down" /></a>
                    <a-menu slot="overlay">
                    <a-menu-item key="1" @click="showEditModal(record)">
                        班级信息
                    </a-menu-item>
                    <a-menu-item key="2" @click="showDetailModal(record,'1')">
                        班级学员
                    </a-menu-item>
                    <a-menu-item key="3" @click="copyEditModal(record)">
                        复制开班
                    </a-menu-item>
                    </a-menu>
                </a-dropdown>
                <!-- <a-divider type="vertical" />
                <a slot="action" href="javascript:;">课程安排</a>
                <a-divider type="vertical" />
                <a slot="action" href="javascript:;">查看</a> -->
                </template>

            </a-table>
        </div>
        <div class="page">
            <a-pagination
            :pageSizeOptions="pageSizeOptions"
            :total="pageParams.totalCount"
            showSizeChanger
            :pageSize="pageParams.perPage"
            v-model="current"
            @change="changePage"
            @showSizeChange="onShowSizeChange"
            :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                <span v-if="props.value==='100'">100条/页</span>
                </template>

            </a-pagination>
        </div>
        <editModal v-if="visible" :isEdit='isEdit' :copy='copyData' :item="modalData"/>
        <detailModal v-if="detailVisible" :item="modalData"/>
    </div>
  </div>
</template>
<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '班级名称', width:'230px', dataIndex: 'class_name', key: 'class_name', scopedSlots: { customRender: 'className' }, fixed: 'left'},
  { title: '上课校区', dataIndex: 'studio_id', key: 'classes.studio_id' ,ellipsis:true,sorter:()=>{}},
  { title: '课程安排', dataIndex: 'class_rule', key: 'class_rule',scopedSlots: { customRender: 'classRule' }},
  { title: '课程科目', dataIndex: 'course_cate_id', key: 'classes.course_cate_id',ellipsis:true,sorter:()=>{}},
  { title: '授课老师', width: '80px', dataIndex: 'tutor_id', key: 'classes.tutor_id' ,ellipsis:true},
  { title: '班主任', width: '80px', dataIndex: 'cc_id', key: 'classes.cc_id' ,ellipsis:true},
  { title: '班级类型', width: '90px', dataIndex: 'class_type', key: 'classes.class_type' ,ellipsis:true,sorter:()=>{}},
  { title: '班级学员', width: '100px', dataIndex: 'class_count', key: 'classes.class_count',align:'center', scopedSlots: { customRender: 'class_count' },sorter:()=>{}},
  { title: '课程进度', width: '100px', dataIndex: 'class_had_course_count', key: 'classes.class_had_course_count',scopedSlots: { customRender: 'class_had_course_count' } ,align:"center",sorter:()=>{}},
  { title: '满班率', width: '120px', dataIndex: 'class_percent', key: 'classes.class_percent',scopedSlots: { customRender: 'classPercent' } ,align:'center',sorter:()=>{}},
  { title: '最近排课', dataIndex: 'class_start', key: 'class_start',align:'center',sorter:()=>{}},
  { title: '操作', key: 'operation', fixed: 'right', width: 60, scopedSlots: { customRender: 'action' }}
]


import tableMixins from '@/common/mixins/table'
import editModal from '@/views/classes/bjgl/editModal'
import detailModal from '@/views/classes/bjgl/detail/index'
import cItems from '@/utils/items'
import moment from 'moment'

export default {
  name: 'bjgl',
  mixins: [ tableMixins ],
  components: {
    editModal,
    detailModal
  },

  data() {
    return {
      loading: false,
      columns,
      studios: [],
      cCategorys: [],
      teachers: [],
      consultants: [],
      classTypes: cItems.classTypes,
      classStatus: cItems.class_status,
      classWeeklys: cItems.classWeeklys,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {
            is_sub:0,
            status:'1'
        },
        "sort": ''
      },
      detailVisible: false,
      exportLoading: false,

    }
  },
  beforeCreate () {
      if(!this.$ls.get('is_sub')){
          this.$ls.set('is_sub', 0)
      }
  },
  created() {
    this.getStudio()
    this.cCategoryDetailAction()
    this.getTeacher()
    this.getConsultant()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getList() {
      this.loading = true
      this.searchParams.search.is_sub = this.$ls.get('is_sub')
      let res = await this.$store.dispatch('scheduleClassesAction', this.searchParams)
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },
    async cCategoryDetailAction() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
        this.cCategorys = res.data
    },

    async getTeacher() {
      let res = await this.$store.dispatch('searchTeacherAction', {})
      this.teachers = res.data
    },
    async getConsultant() {
      let res = await this.$store.dispatch('searchConsultantAction', {})
      this.consultants = res.data
    },
    showDetailModal(item,num) {
      this.modalData = item
      this.modalData.activeIndex = num
      this.detailVisible = true
    },
    hideDetailModal() {
      this.detailVisible = false
    },
    copyEditModal(record){
      this.visible = true
      this.isEdit = 2
      this.modalData = null
      this.copyData = record
    },
    handleIsSub(e){
        this.$ls.set('is_sub', e)
        this.searchParams.search.is_sub = e
        this.$forceUpdate()
    },
    handleTypeButChange(e){
      if(this.searchParams.search.status == e){
        this.searchParams.search.status = undefined
      }else{
        this.searchParams.search.status = e
      }
      this.getList()
    },
    handleChange(pagination, filters, sorter){
      if(sorter.order){
          if(sorter.order === "ascend"){
              this.searchParams['sort'] = `${sorter.columnKey}`
          }else{
              this.searchParams['sort'] = `-${sorter.columnKey}`
          }
      }else{
          this.searchParams['sort'] = ''
          }
      
      this.searchList()
    },
    async toExport(){
      this.exportLoading = true
      let res = await this.$store.dispatch('classesExportAction', {class_id:this.selectedRowKeys})
      const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
      if ('download' in document.createElement('a')) { 
          const link = document.createElement('a')
          link.download = `班级列表.${moment().format('YYYY.MM.DD')}.xls`
          link.style.display = 'none'
          link.href = URL.createObjectURL(blob)
          document.body.appendChild(link)
          link.click()
          URL.revokeObjectURL(link.href) 
          document.body.removeChild(link)
      } else { //其他浏览器
          navigator.msSaveBlob(blob, fileName)
      }
      this.exportLoading = false
    },
    toClose(){
      let that = this
      this.$confirm({
        title: `您确定该班级结业？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('classesCloseAction', {class_id:that.selectedRowKeys})
            if (res) {
              that.$message.success('操作成功~')
              that.selectedRowKeys = []
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    toMixed(){
      let that = this
      this.$confirm({
        title: `您确定合并班级？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('classesMergeAction', {class_id:that.selectedRowKeys})
            if (res) {
              that.$message.success('操作成功~')
              that.selectedRowKeys = []
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
  .className-ellipsis{
    display: inline-block;
    width: 215px;
    white-space: nowrap;
    word-break: break-all;
    text-overflow: ellipsis;
    overflow: hidden;
  }
</style>